import Left from './Left';
import Right from './Right';
import './style.css';

function Portfolio() {
  return (
    <div className="App">
      <Left/>
      <Right/>
    </div>
  );
}
export default Portfolio;
