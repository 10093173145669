import React from 'react'

const Chip = (props) => {
  return (
    <div className="Chip">
        <p className="ChipText">{props.name}</p>
    </div>
  )
}

export default Chip