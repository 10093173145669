import React from 'react'
import Chip from './Chip'

const Job = (props) => {
    const {time, title, desc, ...chipProps} = props;

    const chipNames = Object.keys(chipProps);

  return (
    <div className="glass JobCard">
        <p className="Time">{time}</p>
        <div className="JobWrapper">
        <p className="JobTitle">{title}</p>
        <p className="JobDesc">{desc}</p>
        <div className='ChipWrapper'>        
        {chipNames.map((chipName) => (
        <Chip name={chipProps[chipName]}/>
      ))}
        </div>
        </div>
    </div>
  )
}

export default Job