import React from 'react'
import Job from './Job'

const Scroll = () => {
  const bastianChips = {
    1: 'C#',
    2: 'Blazor',
    3: 'ASP.NET',
  }
  const perpChips = {
    1: 'PHP',
    2: 'JS',
    3: 'SQL'
  }
  const personalChips = {
    1: 'React',
    2: 'Node.js',
    3: 'HTML',
    4: 'CSS',
  }

  return (
    <div className="Right">

      <p className="AboutMe">I'm a passionate fourth-year computer science student at the University of Guelph, 
      currently pursuing a co-op position as a software developer. My interests span across a diverse range of topics, 
      with a particular fascination for front-end design and development.
      <br></br><br></br>In previous co-op positions, I've worked as an individual and with a small team of developers, 
      contributing to a wide range of development projects.
      <br></br><br></br>
      On this site you'll learn more about my previous placements and my completed, and in-progress projects.
      </p>

      <p className="RightHeader">Work Experience</p>
       <Job time="FALL 2022 & SUMMER 2023" title="Software Developer Co-op - Bastian Solutions"
       desc="Through both of my 4-month co-op terms at Bastian Solutions, I worked with a small 
       software team on a wide breadth of projects. My time with Bastian was spent across many different areas, 
       working on physics engines, string parsers, and emulation systems. In my most recent term, 
       I became very familiar with Blazor, updating the existing GUI and proposing and implementing 
       effective changes to increase the end users productivity.
       "
        {...bastianChips}
       />
       <Job time="SUMMER 2022" title="Software Developer Co-op - Perpetual Motion"
       desc="During my 4-month co-op term at Perpetual Motion Sports, I spearheaded specific improvements in their sports league management tool. Using PHP, JS, and MySQL, I developed dynamic web pages that greatly increased productivity.
       I was able to collaborate closely with the owner, allowing me to gain invaluable insights into the workings of a small business. This hands-on experience allowed me to tailor the software to precisely match the requirements of the management system.
       "
        {...perpChips}
       />
       <p className="RightHeader">Projects</p>
       <Job time="2023" title="Personal Portfolio"
       desc="You might recognize this project, since, well, you're on it! 
       My personal portfolio was built and designed by yours truly, using React and HTML/CSS."
       {...personalChips}
       />
    </div>
    
  )
}

export default Scroll