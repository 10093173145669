import React from 'react'
import { BsGithub, BsLinkedin, BsMailbox } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
const AboutMe = () => {
  return (
    <div className="Left">
        <h1>Brendan Moorehead</h1>
        <h2>Software Developer & UI/UX Designer</h2>
        <h3>I am a student pursuing a degree in software development, 
            enthusiastic about front-end development and eager to embrace new 
            challenges across various tech fields.</h3>
            <div className="IconWrapper">
              <a href="https://github.com/BrendanMoorehead" target='_blank'><BsGithub className='Icon'/></a>
              <a href="https://www.linkedin.com/in/brendan-moorehead-319657223/" target='_blank'><BsLinkedin className='Icon'/></a>
              <a href="mailto:bmoorehe@uoguelph.ca"><GrMail className='Icon'/></a>  
            </div>
    </div>
  )
}

export default AboutMe 